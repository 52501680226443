@import "./../../../../_metronic/_assets/sass/components/_variables.demo.scss";

// custom
.myprofile {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-items: center;
}

button.close-form.btn.btn-secondary {
    background: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    right: -15px;
    top: -10px;
    opacity: 0.9;
}

button.close-form.btn.btn-secondary i {
    font-size: 30px;
    color: $secondary_color;
}

.secondary-link {
    text-decoration:'underline';
    cursor: pointer;
    color: $secondary_color;
}

.subuserbox .react-tel-input .form-control {
    position: relative;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0;
    background: #FFFFFF;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    outline: none;
    width: 100%;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-left: 3.5em;
    border: 1px solid #E4E6EF;
}

button.close-form.btn.btn-secondary:hover {
    background-color: transparent !important;
    border: 0;
}

.client-activity{
    .feedback{
        display: none;
    }
    .request-datepicker {
        display: flex;
        align-items: center;
        span {
            padding: 0 10px;
        }
        
    }
    .btn.btn-primary {
        margin-top: 20px;
        margin: 15px;
        margin-left: 10px;
    }
    
}


@media (max-width:1023px) {
    .client-activity{
        .col-lg-3 {
            margin-bottom: 15px;
        }
    }
}

@media (max-width:767px) {
    .client-activity{
        margin-top: 20px;
    }
}