//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//
// Theme colors
// Override primary color variants
$primary: #71B601; // Bootstrap variable
$primary-hover: #71B601; // Custom variable
$primary-light: #E1F0FF; // Custom variable
$primary-inverse: #FFFFFF; // Custom variable
$primary_blue: #2A458C;

$primary_color: var(--primary_color);
$secondary_color: var(--secondary_color);
$primary_button:var(--primary_button_color);
$primary_button_hover:var(--primary_button_hover_color);
$primary_button_text:var(--primary_button_text_color);
$primary_button_text_hover:var(--primary_button_text_hover_color);
$secondary_button:var(--secondary_button_color);
$secondary_button_hover:var(--secondary_button_hover_color);
$secondary_button_text:var(--secondary_button_text_color);
$secondary_button_text_hover:var(--secondary_button_text_hover_color);
$outline_button:var(--outline_button_color);
$outline_button_hover:var(--outline_button_hover_color);
$outline_button_text:var(--outline_button_text_color);
$outline_button_text_hover:var(--outline_button_text_hover_color);
$primary_text:var(--primary_text_color);
$text_link:var(--text_link_color);
$text_link_hover:var(--text_link_hover_color);
$menu_color:var(--menu_color);
$menu_color_hover:var(--menu_color_hover);
$menu_color_active:var(--menu_color_active);